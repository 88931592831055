header {
  display: none;
}

.mfipr {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
