@use '../../base/variables' as v;
@use '../../helpers/mixins' as mix;

.ant-modal-mask {
  z-index: 1050 !important;
}

.ant-modal-wrap {
  z-index: 1050 !important;
}

.ant-modal-wrap {
  overflow-x: hidden;
  max-width: 100vw;
  &.standard-modal {
    z-index: 1050;
    & > .ant-modal {
      top: 50px;
      left: 0;
      width: auto;
      // min-width: 100vw;
      // min-height: 100vh;
      height: auto;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      .ant-modal-content {
        display: inline-block;
        .ant-modal-header,
        .ant-modal-body {
          border-bottom-color: v.$border-gray;
        }
        .ant-modal-header {
          padding: 20px;
          .ant-modal-title {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: v.$text-main;
          }
        }
        .ant-modal-footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: flex-start;
          justify-content: flex-start;
          box-sizing: border-box;
          padding: 20px;
          .submit-button,
          .cancel-button {
            height: 48px;
            span {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 700;
              line-height: 17px;
            }
          }
          .submit-button {
            svg {
              margin-right: 10px;
            }
          }
          .cancel-button {
            margin-left: auto;
            span {
              transition-property: color;
              @include mix.antdTransitionTiming();
            }
            svg {
              margin-right: 10px;
              line {
                stroke: v.$gray-light;
                transition-property: stroke;
                @include mix.antdTransitionTiming();
              }
            }
            &:hover {
              span {
                color: v.$primary;
              }
              svg {
                line {
                  stroke: v.$warning;
                }
              }
            }
          }
        }
      }
    }
  }
}
