.form-tabs {
  width: 80%;
  margin: auto;
}

@media (max-width: 1400px) {
  .form-tabs {
    width: 95%;
  }
}

.title-bar.form {
  background-color: #f1f1f1;
}

.title-bar .back-button {
  color: #111111;
  background-color: #ffffff;
  border: none;
  margin-right: 20px;
}

.ant-tabs-content {
  border: none !important;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
  color: #8b8b8b;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  border-left: 6px solid transparent;
  padding-left: 20px;
  height: 36px;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-active {
  color: #111111;
  background-color: #ffffff;
  border-left: 6px solid #2666d5;
  width: 280px;
}

.tab-with-errors {
  color: #ff0000 !important;
}

.ant-tabs-nav .ant-tabs-tab {
  text-align: left;
  color: #8b8b8b;
  border: none;
}

/* .ant-tabs-ink-bar {
    display: none !important;
} */

.ant-form-item-label > label {
  font-size: 14px;
  font-weight: 500;
  color: #8b8b8b;
}

.ant-form-item-label > label .explanation {
  font-size: 11px;
}

.ant-input,
.ant-select-selection-item,
.ant-checkbox-wrapper {
  color: #111111;
  font-size: 14px;
  font-weight: 400;
}

.ant-input {
  padding: 10px 20px 10px 20px;
}

.ant-input,
.ant-select-single .ant-select-selector {
  min-height: 48px;
  text-align: left;
  padding-left: 20px !important;
}

.ant-input-number-input {
  height: 48px;
  text-align: right;
}

.ant-select-multiple .ant-select-selector {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 48px;
}

.ant-select-selector {
  align-items: center;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 20px;
  padding-left: 10px;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 1px;
  border-color: #e1e1e1;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #111111;
}

.ant-btn.submit-button {
  color: #ffffff;
  background: #1dc270 0 0 no-repeat padding-box;
}

.ant-btn[disabled] {
  opacity: 0.5;
}

.ant-btn.remove-button {
  background-color: #ffebeb;
  color: #111111;
}

.ant-btn.remove-button .anticon-close,
.ant-btn.cancel-button .anticon-close {
  color: #d52626;
}

.schedule-table {
  margin-bottom: 20px;
}

.ant-btn.edit-funding-sources {
  font-size: 12px;
  text-transform: capitalize;
  height: 24px;
  padding-right: 0;
  padding-left: 12px;
}

.ant-btn.edit-funding-sources img {
  margin-left: 10px;
}

.funding-sources-modal {
  max-width: 1600px;
}

.funding-sources-modal .ant-btn {
  height: 48px;
}

.funding-sources-modal .ant-row {
  align-items: center;
  justify-content: space-between;
}

.funding-sources-modal .ant-row .remove-button {
  margin-bottom: 24px;
}

.funding-sources-modal .ant-row:nth-of-type(2) .remove-button {
  margin-bottom: -6px;
}

.funding-sources-modal .ant-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.funding-sources-modal .ant-modal-body {
  padding: 20px;
}

.funding-sources-modal .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.funding-sources-modal .value-input {
  margin-left: 20px;
}

.funding-sources-modal .ant-input-number,
.funding-sources-modal input {
  height: 48px;
}

.funding-sources-modal .source-select .ant-form-item-control,
.funding-sources-modal .source-select .ant-form-item-label {
  width: 100%;
}

.funding-sources-modal .add-source-button {
  color: #2666d5;
}

.funding-sources-modal .value-input {
  width: 20%;
}

.funding-sources-modal .value-input .ant-input-number {
  width: 100%;
}

.financial-effects-modal .value-input .ant-input-number {
  width: 100%;
}

.financial-effects-modal {
  max-width: 1600px;
}

.financial-effects-modal .ant-btn {
  height: 48px;
}

.financial-effects-modal .ant-row {
  align-items: center;
  justify-content: space-between;
}

.financial-effects-modal .ant-row .remove-button {
  margin-bottom: 24px;
}

.financial-effects-modal .ant-row:nth-of-type(2) .remove-button {
  margin-bottom: -6px;
}

.financial-effects-modal .ant-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.financial-effects-modal .ant-modal-body {
  padding: 20px;
}

.financial-effects-modal .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.financial-effects-modal .value-input {
  margin-left: 20px;
}

.financial-effects-modal .ant-input-number,
.financial-effects-modal input {
  height: 48px;
  min-width: 107px;
}

.financial-effects-modal .effect-select .ant-form-item-control,
.financial-effects-modal .effect-select .ant-form-item-label {
  width: 100%;
}

.financial-effects-modal .ant-col {
  width: 100%;
}

.financial-effects-modal .add-effect-button {
  color: #2666d5;
}

.financial-effects-modal .value-input .ant-input-number {
  width: 100%;
}

.year-picker {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.year-picker .label {
  color: #8b8b8b;
}

.year-picker .ant-btn {
  height: 22px;
  width: 45px;
  margin-right: 20px;
  color: #111111;
  background-color: transparent;
  padding: 0;
}

.year-picker .ant-btn.active {
  color: #ffffff;
  background-color: #2666d5;
}

.edit-task-modal .cost-input .ant-input-number {
  width: 100%;
}

.yes-no-toggle {
  display: flex;
}

.yes-no-toggle .buttons {
  min-width: 90px;
}

.yes-no-toggle .ant-btn {
  color: #111111;
  background-color: #ebf2ff;
  border-radius: 0;
  border: none;
  height: 24px;
  padding: 0 10px 0 10px;
}

.yes-no-toggle .yes-button.active {
  color: #ffffff;
  background-color: #1dc270;
}

.yes-no-toggle .no-button.active {
  color: #ffffff;
  background-color: #d52626;
}

.yes-no-toggle .label {
  color: #111111;
  margin-left: 10px;
}

.ant-btn.drawer-toggle {
  font-size: 10px;
  padding: 0;
  background: none;
}

.question-card .ant-card-head-wrapper {
  align-items: center;
}

.question-card .ant-card-head-wrapper .ant-card-extra {
  height: 48px;
  padding: 0 !important;
  width: 140px;
}

.question-tooltip .ant-tooltip-content {
  width: 400px;
}

.public-aid-test .checkbox-info {
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
}
