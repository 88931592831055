@use 'src/scss/base/variables' as v;

.ant-modal-wrap {
  &.indicator-measurement {
    .ant-modal {
      .ant-modal-content {
        min-width: 968px;
        .ant-modal-body {
          padding: 30px 20px;
          .divider {
            content: ' ';
            display: block;
            height: 1px;
            width: calc(100% + 40px);
            position: relative;
            margin: 30px -20px;
            background-color: v.$border-gray;
          }
          .info {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            flex-wrap: wrap;
            width: 100%;
            row-gap: 20px;
            .labeled-info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-content: flex-start;
              justify-content: flex-start;
              gap: 5px;
              width: 50%;
              label {
                font-size: 12px;
                line-height: 14px;
                color: v.$gray-light;
                font-weight: 500;
              }
              p,
              span {
                line-height: 14px;
                color: v.$gray-light;
                font-weight: 500;
                font-size: 12px;
                margin: 0;
                padding: 0;
              }
              &.title {
                width: 100%;
                p,
                span {
                  font-size: 20px;
                  line-height: 24px;
                  font-weight: 500;
                  color: v.$text-main;
                }
              }
            }
          }
          .details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
            row-gap: 20px;
            width: 100%;
            height: auto;
            & > label {
              font-size: 15px;
              line-height: 18px;
              font-weight: 500;
              color: v.$text-main;
            }
            form {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-content: flex-start;
              justify-content: flex-start;
              gap: 20px;
              width: 100%;
              .form-row {
                display: flex;
                flex-direction: row;
                align-content: center;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                min-height: 40px;
                height: auto;
                flex-wrap: nowrap;
                column-gap: 20px;
                .ant-form-item {
                  margin: 0;
                  min-height: 40px;
                  height: auto;
                  .ant-form-item-label {
                    height: inherit;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-start;
                    label {
                      height: auto;
                      font-size: 12px;
                      font-weight: 500;
                      line-height: 14px;
                      color: v.$gray-dark;
                    }
                  }
                  .ant-form-item-control {
                    .ant-form-item-control-input {
                      .ant-form-item-control-input-content {
                        .ant-select {
                          height: 40px;
                          min-height: 40px;
                          max-height: 40px;
                          .ant-select-selector {
                            height: 40px;
                            min-height: 40px;
                            max-height: 40px;
                            border: 1px solid v.$border-gray;
                            .ant-select-selection-search {
                              input {
                                height: 40px;
                                min-height: 40px;
                                max-height: 40px;
                              }
                            }
                            .ant-select-selection-placeholder {
                              font-size: 14px;
                              color: v.$gray-light;
                              font-weight: 400;
                            }
                          }
                        }
                        .ant-input,
                        .ant-input-number {
                          height: 40px;
                          min-height: 40px;
                          max-height: 40px;
                          border: 1px solid v.$border-gray;
                          &::placeholder {
                            font-size: 14px;
                            line-height: 17px;
                            color: v.$gray-light;
                            font-weight: 400;
                          }
                        }
                        .ant-input-number {
                          width: 230px;
                          .ant-input-number-input {
                            height: 40px;
                            width: 230px;
                            text-align: left;
                            padding: 10px 20px;
                            &::placeholder {
                              font-size: 14px;
                              line-height: 17px;
                              color: v.$gray-light;
                              font-weight: 400;
                            }
                          }
                        }
                        .ant-picker {
                          height: 40px;
                          min-height: 40px;
                          width: 230px;
                          border: 1px solid v.$border-gray;
                          input {
                            font-size: 14px;
                            color: v.$text-main;
                            font-weight: 400;
                            &::placeholder {
                              font-size: 14px;
                              color: v.$gray-light;
                              font-weight: 400;
                            }
                          }
                        }
                        textarea {
                          min-height: 150px !important;
                        }
                      }
                    }
                  }
                  &:first-of-type {
                    .ant-form-item-label {
                      width: 226px;
                    }
                  }
                  &.half {
                    .ant-form-item-control {
                      width: 230px;
                    }
                  }
                  &.full {
                    width: 100%;
                    .ant-form-item-control {
                      width: calc(100% - 226px);
                    }
                  }
                  &.textarea {
                    .ant-form-item-label {
                      align-self: flex-start;
                      margin-top: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
