body {
  font-family: Roboto, sans-serif;
  background-color: #fafafa;
  overflow-x: hidden;
  color: #111111;
}

.ant-layout {
  background-color: #fafafa;
  min-height: 100vh;
}

header.ant-layout-header {
  height: 80px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 1400px) {
  header.ant-layout-header {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 90px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 40px;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 1400px) {
  .title-bar {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}

.title-bar div {
  display: flex;
  align-items: center;
}

.title-bar .title {
  font-size: 20px;
  margin-right: 10px;
}

.title-bar .buttons {
  display: flex;
  justify-content: flex-end;
}

.title-bar .buttons .ant-btn {
  margin-left: 10px;
}

.title-bar .buttons .ant-btn:first-of-type {
  margin-left: 0;
}

.title-bar .ant-btn-lg {
  font-size: 14px;
}

main.ant-layout-content {
  background-color: #fafafa;
  width: 100%;
  margin: auto;
}

footer.ant-layout-footer {
  background-color: #fafafa;
  text-align: center;
}

.inner-layout {
  width: 80%;
  margin: auto;
}

@media (max-width: 1400px) {
  .inner-layout {
    width: 95%;
  }
}

.inner-layout .ant-layout-content {
  margin-left: 300px;
  margin-top: 0;
}

@media (max-width: 1400px) {
  .inner-layout .ant-layout-content {
    margin-left: 220px;
  }
}

.navigation-sider {
  background: none;
  position: fixed;
  overflow: auto;
  top: 206px;
  font-size: 30px;
  color: #8b8b8b;
  min-width: 280px !important;
  min-height: 400px;
}

@media (max-width: 1400px) {
  .navigation-sider {
    min-width: 200px !important;
  }
}

.navigation-sider .side-menu {
  padding-left: 0;
  margin-top: 5px;
}

.navigation-sider .side-menu > div {
  padding-left: 0;
  border-left: 6px solid transparent;
  display: flex;
  align-items: center;
  height: 36px;
}

.navigation-sider .side-menu > div > a {
  font-size: 14px;
  font-weight: 600;
  color: #8b8b8b;
  position: relative;
  left: 20px;
}

.navigation-sider .side-menu > div.active-section {
  border-left: 6px solid #2666d5;
  background-color: #ffffff;
  transition: none;
}

.navigation-sider .side-menu > div.active-section > a {
  color: #000000;
}

.object-list {
  width: 80%;
  margin: auto;
}

@media (max-width: 1400px) {
  .object-list {
    width: 95%;
  }
}

.city-site-layout-content {
  padding-left: 20%;
  padding-right: 20%;
  min-height: 280px;
}
