$white: #ffffff;
$bg-light: #fafafa;
$gray-light: #8b8b8b;
$gray-dark: #5e5e5e;
$text-main: #111111;
$border-gray: #ececec;
$popover-border: #e1e1e1;
$primary: #2666d5;
$success: #1dbb6c;
$warning: #dc4a4a;
$warning-light: #ffebeb;
