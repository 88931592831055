@use '../../base/variables' as v;
@use '../../helpers/mixins' as mix;

.ant-modal-wrap {
  &.milestone-edit {
    & > .ant-modal {
      .ant-modal-content {
        .labeled-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          align-content: center;
          width: auto;
          height: auto;
          gap: 10px;
          & > label {
            font-size: 12px;
            font-family: 'Roboto';
            font-weight: 500;
            color: v.$gray-light;
            line-height: 14px;
          }
          & > p {
            font-size: 15px;
            line-height: 18px;
            color: v.$text-main;
            font-weight: 500;
            margin: 0;
          }
        }
      }
    }
  }
  &.milestone-create,
  &.milestone-edit {
    & > .ant-modal {
      top: 0;
      left: 0;
      width: auto;
      min-width: 100vw;
      min-height: 100vh;
      height: auto;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      .ant-modal-content {
        display: inline-block;
        .ant-modal-header,
        .ant-modal-body {
          border-bottom-color: v.$border-gray;
        }
        .ant-modal-header {
          padding: 20px;
          .ant-modal-title {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: v.$text-main;
          }
        }
        .ant-modal-body {
          padding: 26px 20px 30px 20px;
          form {
            .ant-form-item {
              margin: 0;
            }
            & > .divider {
              display: block;
              content: ' ';
              position: relative;
              height: 1px;
              background-color: v.$border-gray;
              margin: 30px -20px;
            }
            .ant-form-item {
              .ant-form-item-label {
                padding: 0;
                margin-bottom: 10px;
                overflow: hidden;
                & > label {
                  font-size: 12px;
                  line-height: 14px;
                  color: v.$gray-light;
                  font-weight: 500;
                  white-space: nowrap;
                  &.ant-form-item-required {
                    &::before {
                      color: v.$warning;
                    }
                  }
                }
              }
              .ant-form-item-control {
                .ant-select {
                  height: 38px;
                  max-height: 38px;
                  min-height: 38px;
                  .ant-select-selector {
                    height: inherit;
                    min-height: 38px;
                  }
                  .ant-select-selection-item {
                    font-size: 14px;
                    font-weight: 400;
                    color: v.$text-main;
                  }
                }
              }
            }
            & > .form-group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-content: center;
              justify-content: flex-start;
              width: auto;
              gap: 20px;
              & > label {
                font-size: 15px;
                line-height: 18px;
                font-weight: 500;
                color: v.$text-main;
              }
              & > .inputs {
                display: flex;
                flex-direction: row;
                align-content: center;
                align-items: center;
                justify-content: flex-start;
                gap: 30px;
                .ant-form-item {
                  min-width: 290px;
                  width: auto;
                  .ant-form-item-label {
                    padding: 0;
                    margin-bottom: 10px;
                    overflow: hidden;
                    & > label {
                      font-size: 12px;
                      line-height: 14px;
                      color: v.$gray-light;
                      font-weight: 500;
                      white-space: nowrap;
                      &.ant-form-item-required {
                        &::before {
                          color: v.$warning;
                        }
                      }
                    }
                  }
                  .ant-input {
                    padding: 0;
                    height: 40px;
                    box-sizing: border-box;
                    max-height: 40px;
                    min-height: 40px;
                  }
                  .ant-picker {
                    width: 100%;
                    height: 40px;
                    min-height: 40px;
                    max-height: 40px;
                  }
                  &:nth-of-type(1) {
                    min-width: 230px;
                  }
                }
              }
            }
          }
        }
        .ant-modal-footer {
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          justify-content: flex-start;
          padding: 20px;
          width: 100%;
          & > button {
            span {
              font-size: 14px;
              line-height: 17px;
              text-transform: uppercase;
              font-family: 'Roboto';
              font-weight: 700;
            }
            svg {
              margin-right: 10px;
            }
            &.submit-button {
              color: v.$white;
            }
            &.cancel-button {
              margin-left: auto;
              color: v.$text-main;
              svg {
                line {
                  transition-property: stroke;
                  @include mix.antdTransitionTiming();
                }
              }
              &:hover {
                svg {
                  line {
                    stroke: v.$warning;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// detached from original element part of antd Select ( modal on modal )
.add-milestone-option-dropdown {
  .ant-divider {
    margin: 20px 0 0 0;
  }
  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
    .ant-form-item {
      margin: 0;
      width: 100%;
      &:nth-of-type(2) {
        align-content: center;
        align-items: center;
        justify-content: center;
        .ant-form-item-control-input-content {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          button {
            width: 50%;
          }
        }
      }
    }
  }
}
