@use 'src/scss/base/variables' as v;

.custom-icon {
  &.status {
    width: 10px;
    height: 10px;
    background-color: transparent;
    content: ' ';
    display: block;
    border: 2px solid v.$border-gray;
    border-radius: 30px;
    &.warning {
      border-color: v.$warning;
    }
    &.success {
      border-color: v.$success;
    }
    &.primary {
      border-color: v.$primary;
    }
  }
}
