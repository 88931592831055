@use '../base/variables' as v;

.popover-standard {
  & > .ant-popover-content {
    // & > .ant-popover-arrow {
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    //   border-width: 6px 7px 6px 0;
    //   border-color: transparent #007bff transparent transparent;
    //   left: 4px;
    //   &::after {
    //     width: 0;
    //     height: 0;
    //     border-style: solid;
    //     border-width: 5px 5px 5px 0;
    //     border-color: transparent v.$white transparent transparent;
    //     display: block;
    //     content: ' ';
    //     position: absolute;
    //     left: 4px;
    //   }
    // }
    & > .ant-popover-inner {
      border-radius: 8px;
      border: 1px solid v.$popover-border;
      & > .ant-popover-inner-content {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        & > button {
          cursor: pointer;
          background-color: transparent;
          border: 0px solid transparent;
          width: 100%;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          color: v.$gray-dark;
          opacity: 0.5;
          transition-property: opacity;
          transition-timing-function: ease-in-out;
          transition-duration: 250ms;
          user-select: none;
          &:hover {
            opacity: 1;
          }
        }
        & > .spacer {
          width: 100%;
          height: 1px;
          background-color: v.$popover-border;
          content: ' ';
        }
      }
    }
  }
}

.popover-options-list {
  & > .ant-popover-content {
    & > .ant-popover-inner {
      border-radius: 8px;
      border: 1px solid v.$popover-border;
      & > .ant-popover-inner-content {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            label {
              &.ant-checkbox-wrapper {
                font-size: 14px;
                line-height: 17px;
                color: v.$text-main;
                font-weight: 400;
                .ant-checkbox {
                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: v.$primary;
                      border-color: v.$primary;
                      &::after {
                        border-color: v.$white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
