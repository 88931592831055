@use 'src/scss/base/variables' as v;

.ant-message {
  .ant-message-notice {
    &.standard-toast {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: center;
      justify-content: flex-start;
      margin-right: 183px;
      .ant-message-notice-content {
        padding: 20px 20px 20px 15px;
        .ant-message-custom-content {
          &.ant-message-success {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: flex-start;
            justify-content: flex-start;
            column-gap: 10px;
            svg {
              margin-top: 1px;
            }
            & > span {
              display: flex;
              flex-direction: column;
              align-items: center;
              align-content: flex-start;
              justify-content: flex-start;
              row-gap: 10px;
              p {
                margin: 0;
                padding: 0;
                font-size: 14px;
                line-height: 20px;
                color: v.$gray-dark;
                font-weight: 400;
                max-width: 334px;
                height: auto;
                text-align: left;
                width: 100%;
                &.title {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  color: v.$text-main;
                  user-select: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
