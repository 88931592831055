@use '../base/variables' as v;
@use '../helpers/mixins' as mix;

.standard-button {
  padding: 0 20px;
  border: 2px solid v.$border-gray;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  transition-property: border, background-color;
  @include mix.antdTransitionTiming();
  p,
  span {
    margin: 0;
    padding: 0;
    user-select: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    transition-property: color;
    @include mix.antdTransitionTiming();
  }
  svg {
    margin-right: 10px;
    transition-property: fill;
    @include mix.antdTransitionTiming();
    rect {
      transition-property: fill;
      @include mix.antdTransitionTiming();
    }
  }
  &:hover {
    border-color: transparent;
    p,
    span {
      color: v.$primary;
    }
  }
  &.filter {
    svg {
      margin-right: 10px;
    }
    p,
    span {
      color: v.$text-main;
      text-transform: none;
    }
    .selected {
      font-size: 14px;
      line-height: 17px;
      margin-left: 5px;
      color: v.$gray-light;
      font-weight: 400;
    }
    &:hover {
      p,
      .selected {
        color: v.$primary;
      }
    }
  }
  &.primary {
    border-color: v.$primary;
    background-color: v.$primary;
    p,
    span {
      color: v.$white;
      font-weight: 700;
    }
    &:hover {
      border-color: v.$border-gray;
      background-color: v.$white;
      p,
      span {
        color: v.$primary;
      }
      svg {
        rect {
          fill: v.$primary;
        }
      }
    }
  }
  &.secondary {
    border-color: v.$border-gray;
    background-color: transparent;
    p,
    span {
      color: v.$text-main;
    }
    svg {
      rect {
        fill: v.$primary;
      }
    }
    &:hover {
      border-color: transparent;
      p,
      span {
        color: v.$primary;
      }
    }
  }
}
