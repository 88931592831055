.map-container {
  border-bottom: 1px solid #ececec;
  margin-bottom: 38px;
}

.map-details-row {
  height: 560px;
  overflow: auto;
}

.map-details-row-empty {
  height: 450px;
}

.map-details-card {
  width: 100%;
}

.map-filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 12px;
}

.map-filter-button {
  flex-basis: 0;
  flex: 1;
  margin: 6px;
}
