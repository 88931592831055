header.ant-layout-header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}

header > div.left-menu {
  display: flex;
  align-items: center;
}

header > div.left-menu > div.ant-divider {
  height: 50px;
  width: 2px;
  color: #ececec;
  margin-left: 40px;
  margin-right: 40px;
}

header > div.left-menu > ul > li {
  height: 100%;
}

header > div.left-menu > ul > li > a {
  height: 100%;
}

ul.ant-menu {
  background-color: transparent;
  border-bottom: none;
}

ul.ant-menu > li.ant-menu-item > a {
  color: #111111;
}

ul.ant-menu > li.ant-menu-item-selected > a {
  color: #2666d5 !important;
}

header > div.right-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.user-info {
  color: #111111;
  padding-left: 20px;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: middle;
  padding: 0 15px !important;
}

@media (max-width: 1400px) {
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 5px !important;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 10px;
}

header > div.left-menu > ul > li,
header > div.left-menu > ul > li > a {
  height: 38px;
  line-height: 38px;
}

.city-link {
  padding-left: 2px;
}

a.city-link {
  color: black;
}

.version:hover {
  cursor: pointer;
  color: #2666d5;
}

.dropdown-menu .ant-btn-link {
  width: 120px;
  height: 40px;
  color: black;
}

.dropdown-menu .ant-btn {
  text-transform: none !important;
  background-color: transparent !important;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.dropdown-menu .ant-btn:hover {
  color: #2666d5;
}

.dropdown-menu .left-btn {
  border-radius: unset;
  border-right: 1px solid #d9d9d9;
  width: 160px;
}

.dropdown-menu .left-btn.fullwidth {
  width: 100%;
}

.dropdown-menu .right-btn {
  padding-left: 15px;
  padding-right: 15px;
  color: rgba(0, 0, 0, 0.65);
  width: 44px;
}

.dropdown-menu .right-btn:hover {
  color: #2666d5;
}

.dropdown-menu .ant-btn .ant-btn-icon-only {
  background-color: #f1f1f1 !important;
}

.dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}

.dropdown-menu {
  padding: 0;
  border: 1px solid #d9d9d9;
}

.section-header {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  line-height: 50px;
  font-family: 'Roboto';
  margin-bottom: 0;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
}

.dropdown-menu-right {
  top: -12px !important;
}

.section-header {
  text-align: left;
  font: normal normal bold 28px/50px Roboto;
  margin-bottom: 0;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
}

.arrows {
  font-size: 10px;
}
