@import 'layout.css';
@import 'header.css';
@import 'details.css';
@import 'table.css';
@import 'form.css';
@import 'map.css';

/* disable number input arrows */
.ant-input-number-handler-wrap {
  display: none;
}

span.ant-tag {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #111111;
  opacity: 1;
  background: #ebf2ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

button.ant-btn {
  font-weight: 700;
  text-transform: uppercase;
  height: 38px;
  background-color: #f1f1f1;
  border: none;
  padding-left: 20px;
  padding-right: 25px;
}

button.ant-btn-primary {
  background-color: #2666d5;
}

button.ant-btn > img {
  margin-right: 10px;
}

.form-submit-section {
  text-align: right;
}

.ant-back-top {
  right: 5%;
}

.ant-alert-info {
  width: 100%;
  background-color: #f5f9ff;
  border: none;
  margin-bottom: 30px;
}

.ant-alert-info .anticon-info-circle {
  color: #2666d5;
}

.quarter-picker {
  max-width: 350px;
}

.quarter-picker .ant-picker {
  height: 48px;
}

.unstyled-link {
  cursor: pointer;
}

.unstyled-link.disabled {
  color: #bbbbbb;
}

.login-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container .ant-form-item {
  align-items: center;
}

.login-container > form {
  width: 900px;
}

.login-container input {
  height: 48px;
  font-size: 16px;
}

.login-container .ant-input-password {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.login-container .ant-input-suffix {
  display: flex;
  align-items: center;
}

.add-indicator-modal {
  width: 800px !important;
}

.finance-realisation-modal {
  width: 700px !important;
}

.ant-select-selection-search-input {
  height: 45px !important;
}

.section-loader {
  text-align: center;
}

.add-unit-btn {
  flex: none;
  padding: 8px;
  display: block;
  cursor: pointer;
  margin: 5px 0 0 10px !important;
}
