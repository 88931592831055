@use '../../base/variables' as v;

.ant-modal-wrap {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        form {
          &.modal-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
            gap: 25px;
            .form-row {
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              height: 40px;
              flex-wrap: nowrap;
              column-gap: 20px;
              .ant-form-item {
                margin: 0;
                height: 40px;
                .ant-form-item-label {
                  height: inherit;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  align-content: center;
                  justify-content: flex-start;
                  label {
                    height: auto;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14px;
                    color: v.$gray-dark;
                  }
                }
                .ant-form-item-control {
                  .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                      .ant-select {
                        height: 40px;
                        min-height: 40px;
                        max-height: 40px;
                        .ant-select-selector {
                          height: 40px;
                          min-height: 40px;
                          max-height: 40px;
                          border: 1px solid v.$border-gray;
                          .ant-select-selection-search {
                            input {
                              height: 40px;
                              min-height: 40px;
                              max-height: 40px;
                            }
                          }
                          .ant-select-selection-placeholder {
                            font-size: 14px;
                            color: v.$gray-light;
                            font-weight: 400;
                          }
                        }
                      }
                      .ant-input {
                        height: 40px;
                        min-height: 40px;
                        max-height: 40px;
                        border: 1px solid v.$border-gray;
                        &::placeholder {
                          font-size: 14px;
                          line-height: 17px;
                          color: v.$gray-light;
                          font-weight: 400;
                        }
                      }
                      .ant-picker {
                        height: 40px;
                        min-height: 40px;
                        width: 135px;
                        border: 1px solid v.$border-gray;
                        input {
                          font-size: 14px;
                          color: v.$text-main;
                          font-weight: 400;
                          &::placeholder {
                            font-size: 14px;
                            color: v.$gray-light;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                }
                &:first-of-type {
                  .ant-form-item-label {
                    width: 226px;
                  }
                }
                &.half {
                  .ant-form-item-control {
                    width: 230px;
                  }
                }
                &.full {
                  width: 100%;
                  .ant-form-item-control {
                    width: calc(100% - 226px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
