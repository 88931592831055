@use 'src/scss/base/variables' as v;
@use 'src/scss/helpers/mixins' as mix;

.ant-card {
  &.tabs-card {
    border-color: transparent;
    .ant-card-head {
      padding: 28px 30px 30px 30px;
      border: none;
      .ant-card-head-wrapper {
        .ant-card-head-title {
          font-family: 'Roboto';
          font-weight: 700;
          color: v.$text-main;
          line-height: 34px;
          font-size: 28px;
          padding: 0;
        }
      }
    }
    .ant-card-body {
      padding: 0;
      margin: 0;
      .ant-tabs {
        .ant-tabs-nav {
          margin-bottom: 0px;
          &::before {
            border-bottom-color: v.$border-gray;
          }
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                .ant-tabs-tab-btn {
                  font-weight: 700;
                  font-size: 14px;
                  color: v.$gray-light;
                }
                &:nth-of-type(1) {
                  margin-left: 30px;
                }
                &:not(:first-of-type) {
                  margin-left: 40px;
                }
                &.ant-tabs-tab-active {
                  .ant-tabs-tab-btn {
                    color: v.$primary;
                  }
                }
              }
              .ant-tabs-ink-bar {
                background-color: v.$primary;
              }
            }
          }
        }
      }
    }
  }
}
