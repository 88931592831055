@use 'src/scss/base/variables' as v;
@use 'src/scss/helpers/mixins' as mix;

#schedule,
.project-schedule-card {
  .ant-card-head {
    border-bottom-color: v.$border-gray;
    padding: 30px;
    .ant-card-head-title,
    .ant-card-extra {
      padding: 0;
      margin: 0;
      .extra {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: 10px;
        button {
          span {
            text-transform: none;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 700;
          }
        }
        .realization {
          box-sizing: border-box;
          border: 2px solid v.$border-gray;
          background-color: v.$white;
          transition-property: background-color, border-color;
          @include mix.antdTransitionTiming();
          span {
            font-size: 14px;
            font-weight: 700;
            color: v.$text-main;
            text-transform: none;
          }
          &:hover {
            background-color: v.$bg-light;
            border-color: v.$bg-light;
          }
        }
      }
    }
    .ant-card-head-title {
      font-size: 28px;
      line-height: 38px;
      color: v.$text-main;
      font-weight: 700;
    }
  }
  .ant-card-body {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
    row-gap: 40px;
    .labeled-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;
      justify-content: flex-start;
      gap: 20px;
      width: 100%;
      label {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: v.$text-main;
      }
      .ant-table-wrapper {
        width: 100%;
        .ant-table {
          .ant-table-container {
            .ant-table-content {
              table {
                .ant-table-thead {
                  height: 37px;
                  .ant-table-cell {
                    border: 0px solid transparent;
                    background-color: v.$bg-light;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    color: v.$text-main;
                    &::before {
                      display: none;
                    }
                  }
                }
                .ant-table-tbody {
                  .ant-table-row {
                    .ant-table-cell {
                      font-size: 14px;
                      color: v.$gray-dark;
                      line-height: 17px;
                      height: 52px;
                      box-sizing: border-box;
                      border-bottom: 1px solid v.$border-gray;
                      .edit-funding-sources {
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: nowrap;
                        height: 24px;
                        padding-right: 9px;
                        span {
                          font-size: 12px;
                          font-weight: 500;
                          color: v.$text-main;
                          line-height: 12px;
                          margin-right: 9px;
                          transition-property: color;
                          @include mix.antdTransitionTiming();
                        }
                        svg {
                          path {
                            transition-property: fill;
                            @include mix.antdTransitionTiming();
                          }
                        }
                        &:hover {
                          span {
                            color: v.$primary;
                          }
                          svg {
                            path {
                              fill: v.$primary;
                            }
                          }
                        }
                      }
                      .action-icon {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        box-sizing: border-box;
                        border: 0px solid transparent;
                        background-color: transparent;
                        padding: 0;
                        margin: 0;
                        cursor: pointer;
                        svg {
                          path {
                            transition-property: fill;
                            @include mix.antdTransitionTiming();
                          }
                          line {
                            transition-property: stroke;
                            @include mix.antdTransitionTiming();
                          }
                        }
                        &:hover {
                          svg {
                            path {
                              fill: v.$primary;
                            }
                          }
                        }
                        &.warning {
                          &:hover {
                            svg {
                              line {
                                stroke: v.$warning;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
