@use 'src/scss/base/variables' as v;

.ant-modal-wrap {
  &.add-result {
    .ant-modal {
      top: 100px;
      .ant-modal-content {
        min-width: 800px;
        .ant-modal-body {
          padding: 20px;
        }
      }
    }
  }
}
