@use 'src/scss/base/variables' as v;

.ant-modal-wrap {
  &.add-indicator-config {
    .ant-modal {
      .ant-modal-content {
        min-width: 968px;
        .ant-modal-body {
          padding: 20px;
          .indicator-select {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            align-content: center;
            justify-content: flex-start;
            gap: 20px;
            & > label {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: v.$text-main;
            }
            .actions {
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;
              justify-content: flex-start;
              height: 38px;
              width: 100%;
              gap: 10px;
              & > button {
                height: 38px;
              }
              & > .ant-select {
                height: 38px;
                min-height: 0px;
                max-height: 38px;
                flex-grow: 1;
                .ant-select-selector {
                  height: 38px;
                  min-height: 38px;
                  max-height: 38px;
                  input {
                    height: 38px !important;
                  }
                }
              }
            }
            & > .info-box {
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              align-content: center;
              justify-content: flex-start;
              box-sizing: border-box;
              padding: 20px;
              height: 58px;
              gap: 10px;
              background-color: #f5f9ff;
              p {
                margin: 0;
                font-size: 14px;
                line-height: 17px;
                color: v.$text-main;
                font-weight: 500;
              }
              svg {
                path {
                  fill: v.$primary;
                }
              }
            }
            .indicator-info {
              display: flex;
              flex-direction: column;
              align-content: flex-start;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 6px;
              min-height: 40px;
              width: 100%;
              position: relative;
              label {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: v.$gray-light;
              }
              p {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: v.$text-main;
                margin: 0;
              }
              button {
                height: 40px;
                width: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                justify-content: center;
                border: 0px solid transparent;
                background-color: v.$warning-light;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                svg {
                  line {
                    stroke: v.$warning;
                  }
                }
              }
            }
          }
          .divider {
            content: ' ';
            display: block;
            height: 1px;
            width: calc(100% + 40px);
            position: relative;
            margin: 30px -20px;
            background-color: v.$border-gray;
          }
          .config-form {
            & > label {
              margin-bottom: 20px;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: v.$text-main;
              display: block;
            }
            form {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-content: flex-start;
              justify-content: flex-start;
              gap: 25px;
              .form-row {
                display: flex;
                flex-direction: row;
                align-content: center;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                height: 40px;
                flex-wrap: nowrap;
                column-gap: 20px;
                .ant-form-item {
                  margin: 0;
                  height: 40px;
                  .ant-form-item-label {
                    height: inherit;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-start;
                    label {
                      height: auto;
                      font-size: 12px;
                      font-weight: 500;
                      line-height: 14px;
                      color: v.$gray-dark;
                    }
                  }
                  .ant-form-item-control {
                    .ant-form-item-control-input {
                      .ant-form-item-control-input-content {
                        .ant-select {
                          height: 40px;
                          min-height: 40px;
                          max-height: 40px;
                          .ant-select-selector {
                            height: 40px;
                            min-height: 40px;
                            max-height: 40px;
                            border: 1px solid v.$border-gray;
                            .ant-select-selection-search {
                              input {
                                height: 40px;
                                min-height: 40px;
                                max-height: 40px;
                              }
                            }
                            .ant-select-selection-placeholder {
                              font-size: 14px;
                              color: v.$gray-light;
                              font-weight: 400;
                            }
                          }
                        }
                        .ant-input,
                        .ant-input-number {
                          height: 40px;
                          min-height: 40px;
                          max-height: 40px;
                          border: 1px solid v.$border-gray;
                          &::placeholder {
                            font-size: 14px;
                            line-height: 17px;
                            color: v.$gray-light;
                            font-weight: 400;
                          }
                        }
                        .ant-input-number {
                          width: 230px;
                          .ant-input-number-input {
                            height: 40px;
                            width: 230px;
                            text-align: left;
                            padding: 10px 20px;
                            &::placeholder {
                              font-size: 14px;
                              line-height: 17px;
                              color: v.$gray-light;
                              font-weight: 400;
                            }
                          }
                        }
                        .ant-picker {
                          height: 40px;
                          min-height: 40px;
                          width: 135px;
                          border: 1px solid v.$border-gray;
                          input {
                            font-size: 14px;
                            color: v.$text-main;
                            font-weight: 400;
                            &::placeholder {
                              font-size: 14px;
                              color: v.$gray-light;
                              font-weight: 400;
                            }
                          }
                        }
                      }
                    }
                  }
                  &:first-of-type {
                    .ant-form-item-label {
                      width: 226px;
                    }
                  }
                  &.half {
                    .ant-form-item-control {
                      width: 230px;
                    }
                  }
                  &.full {
                    width: 100%;
                    .ant-form-item-control {
                      width: calc(100% - 226px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
