.top-level-card > .ant-card-head-title {
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 10px;
}

.top-level-card {
  margin-bottom: 30px;
  border: none;
}

.top-level-card > .ant-card-head {
  font-weight: 700;
  font-size: 28px;
  text-align: left;
}

.detail-card {
  border: none;
}

.detail-card .ant-card-head {
  border-bottom: none;
  color: #8b8b8b;
  font-weight: 400;
}

.detail-card .ant-card-body {
  font-size: 20px;
  padding-top: 0;
}

.detail-card .ant-card-body p {
  font-size: 20px;
  font-weight: 400;
}

.detail-card .ant-tag {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

.detail-card .ant-tag span {
  font-size: 14px;
  font-weight: 500;
}

.second-level-card {
  border: none;
  text-align: left;
}

.second-level-card .ant-card-head-title {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.checkbox-info {
  margin-top: 12px;
}

.collapse-info {
  border: none;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

.collapse-info > .ant-collapse-item-active {
  border: 1px solid #ececec;
  background-color: #ffffff;
}

.collapse-info-panel {
  margin-bottom: 20px;
}

.collapse-info-panel > .ant-collapse-header {
  font-weight: 700;
}

.wpf-export .ant-upload-list {
  width: 160px;
}

.checkbox-info .toggle-value {
  width: 44px;
  text-align: center;
  color: #8b8b8b;
  background-color: #ececec;
  font-weight: 700;
  opacity: 1 !important;
}

.collapse-info .toggle-value.yes,
.checkbox-info .toggle-value.yes,
.checkbox-info .toggle-value.yes:hover {
  color: #ffffff;
  background-color: #1dc270;
}

.collapse-info .toggle-value.no,
.checkbox-info .toggle-value.no,
.checkbox-info .toggle-value.no:hover {
  color: #ffffff;
  background-color: #d52626;
}

.collapse-info .toggle-value.partially,
.collapse-info .toggle-value.partially:hover {
  color: #ffffff;
  background-color: #e0e01f;
}

.re-granting .toggle-value.yes,
.re-granting .toggle-value.yes:hover {
  color: #ffffff;
  background-color: #1dc270;
}

.re-granting .toggle-value.no,
.re-granting .toggle-value.no:hover {
  color: #ffffff;
  background-color: #d52626;
}

.ant-row.checkbox-info div:last-of-type {
  margin-left: 10px;
  max-width: 80%;
}

.public-aid-test-answers .checkbox-info {
  margin-bottom: 20px;
}
