@use '../base/variables' as v;

.tooltip-standard-dark {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: v.$gray-dark;
      }
    }
    .ant-tooltip-inner {
      padding: 8px 12px;
      font-size: 12px;
      line-height: 20px;
      color: v.$white;
      font-weight: 500;
      border-radius: 8px;
      background-color: v.$gray-dark;
      box-shadow: 0px 6px 25px #1111111f;
    }
  }
}
