@use 'src/scss/base/variables' as v;

.ant-card {
  &.standard-card {
    .ant-card-head {
      border-bottom-color: v.$border-gray;
      padding: 28px 30px 30px 30px;
      .ant-card-head-title,
      .ant-card-extra {
        padding: 0;
        margin: 0;
        .extra {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: center;
          gap: 10px;
          button {
            span {
              text-transform: none;
              font-family: 'Roboto';
              font-size: 14px;
              font-weight: 700;
            }
          }
        }
      }
      .ant-card-head-title {
        font-size: 28px;
        line-height: 34px;
        color: v.$text-main;
        font-weight: 700;
      }
    }
    .ant-card-body {
      padding: 30px 30px 35px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;
      justify-content: flex-start;
      row-gap: 40px;
      box-sizing: border-box;
    }
  }
}
