.ant-table {
  font-size: 12px;
}

.ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 700;
  border-bottom: none;
}

.activity-projects-table .ant-table-tbody > tr:nth-last-of-type(2) > td {
  border-bottom: none;
}

.activity-projects-table .ant-table-tbody > tr:last-of-type > td {
  background-color: #fafafa;
  font-weight: 700;
  border-bottom: none;
}

.funding-table .ant-table-tbody > tr:last-of-type > td {
  background-color: #fafafa;
  font-weight: 700;
  border-bottom: none;
}

.funding-table .summary-row {
  background-color: #fafafa;
  font-weight: 700;
}

.funding-table .activity-title-row {
  background-color: #fafafa;
  font-weight: 700;
  text-align: center;
}

.object-list .ant-table-thead > tr > th {
  background-color: #ffffff;
  border-bottom: 1px solid #f1f1f1;
}

.object-list .ant-table-tbody {
  font-size: 14px;
}

.pagination-row {
  margin-top: 40px;
  font-family: Roboto, sans-serif;
}

.pagination-row .ant-pagination-item-active {
  color: #ffffff;
  background-color: #2666d5;
  border: 1px solid transparent;
}

.pagination-row .ant-pagination-item-active a {
  color: #ffffff;
  font-family: Roboto, sans-serif;
}

.pagination-row li {
  height: 38px;
  width: 38px;
  color: #5e5e5e;
  background-color: #fafafa;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: #fafafa;
}

.pagination-row .ant-pagination-item-link span {
  margin-top: 12px;
}

.pagination-row .ant-pagination-item a {
  margin-top: 5px;
}

.pagination-row .ant-pagination-options {
  width: 124px;
  float: right;
}

.pagination-row .ant-input {
  padding: 10px 20px 10px 20px;
}

.pagination-row .ant-input,
.pagination-row .ant-select-single .ant-select-selector {
  min-height: 38px;
  width: 124px;
  text-align: center;
  background-color: #fafafa;
}

.pagination-row .ant-select-selection-item {
  font-weight: 700;
  color: #5e5e5e;
}

.status-circle {
  display: inline-block;
  background-color: transparent;
  height: 10px;
  border-radius: 50%;
  width: 10px;
  margin-right: 10px;
}

.status-circle.draft {
  border: 2px solid #f79220;
}

.status-circle.published {
  border: 2px solid #2666d5;
}

.task-funding-error {
  color: #d52626;
}

.primary-cost-exceeded {
  background-color: #fafafa;
}

.primary-cost-not-exceeded {
  background-color: #fafafa;
}
