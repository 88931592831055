@use '../../base/variables' as v;
@use '../../helpers/mixins' as mix;

.ant-modal-wrap {
  &.project-realization {
    .ant-modal-content {
      min-width: 476px;
      .ant-modal-body {
        padding: 40px 40px 40px 20px;
        #project-realization {
          display: flex;
          flex-direction: column;
          align-content: flex-start;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          .input-row {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-content: flex-start;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            .ant-form-item {
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: row;
              align-content: flex-start;
              align-items: center;
              justify-content: flex-start;
              width: 100%;

              .ant-form-item-label {
                label {
                  font-size: 12px;
                  font-weight: 500;
                  color: v.$gray-light;
                }
              }

              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  display: flex;
                  flex-direction: row;
                  align-content: flex-end;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 10px;
                  input {
                    width: 170px;
                    height: 40px;
                    border: 1px solid v.$border-gray;
                    &::placeholder {
                      font-size: 14px;
                      color: v.$gray-light;
                      font-weight: 400;
                      line-height: 17px;
                    }
                  }
                }
              }
            }
            .extra {
              font-size: 14px;
              color: v.$gray-light;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
